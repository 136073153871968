<template>
  <div style="margin: 20px;">
    <h3>Individual Performance Commitment and Review</h3>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card shadow="never">
          <el-row>
            <el-form :inline="true" @submit.native.prevent="search()">
              <el-form-item>
                <el-input v-model="searchterm" size="medium" placeholder="Search personnel" clearable style="width: 250px;" />
              </el-form-item>
              <el-form-item>
                <el-select v-model="deptype" size="medium" placeholder="Search office" filterable clearable style="width: 250px;">
                  <el-option v-for="department in departments" :key="department.id" :value="department.id" :label="department.acronym">
                    {{ department.fullname + ' (' + department.acronym + ')' }}
                    <span v-if="!department.head_id" style="color: #F56C6C; font-weight: bold;">No Head</span>
                    <!--
                    <span v-if="department.fullname.length < 40 " style="word-break: break-word">{{ department.fullname }}</span>
                    <span v-if="department.fullname.length >= 40 " style="word-break: break-word">{{ department.fullname.substring(0, 40) + "..." }}</span>
                    -->
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Search" placement="top">
                  <el-button :icon="icon_search" type="primary" size="medium" @click.native.prevent="search()" />
                </el-tooltip>
              </el-form-item>
              <el-form-item>
                <el-tooltip content="Refresh" placement="top">
                  <el-button :icon="icon_refresh" type="info" size="medium" @click.native.prevent="refresh()" />
                </el-tooltip>
              </el-form-item>
            </el-form>
          </el-row>
          <el-row>
            <el-table v-loading="loading" ref="singleTable" :data="ipcr_personnel" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
              <el-table-column label="Personnel" width="">
                <template slot-scope="scope">
                  <span style="word-break: break-word;">{{ scope.row.fullname }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Office" width="">
                <template slot-scope="scope">
                  <el-tooltip v-if="scope.row.depart" :key="'iftpipcr0' + scope.row.id" :content="scope.row.depart.fullname" effect="dark" placement="right">
                    <span style="word-break: break-word; cursor: default;">{{ scope.row.depart.acronym }}</span>
                  </el-tooltip>
                  <span v-else style="color: #F56C6C; font-weight: bold;">No Office</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Operation" width="90" align="right">
                <template slot-scope="scope">
                  <div style="margin-top: 8px">
                    <div v-if="scope.row.id !== ipcr_id">
                      <el-tooltip v-if="scope.row.ipcr" :key="'iftpipcr1' + scope.row.id" effect="dark" content="List IPCR files" placement="top">
                        <el-badge :key="'ifbeipcr2' + scope.row.id" :value="scope.row.ipcr" class="item" style="margin-right: 30px;">
                          <el-button :key="'ifbnipcr3' + scope.row.id" type="primary" size="mini" @click="view(scope.row)"><i class="fa fa-list" /></el-button>
                        </el-badge>
                      </el-tooltip>
                      <el-tooltip v-else :key="'elsetpipcr4' + scope.row.id" effect="dark" content="List IPCR files" placement="top">
                        <el-button :key="'elsebnipcr5' + scope.row.id" type="primary" size="mini" style="margin-right: 15px;" @click="view(scope.row)"><i class="fa fa-list" /></el-button>
                      </el-tooltip>
                    </div>
                    <div v-else>
                      <el-tooltip :key="'elsetpipcr12' + scope.row.id" effect="dark" content="Close IPCR files" placement="top">
                        <el-button :key="'elsebnipcr13' + scope.row.id" type="danger" size="mini" icon="el-icon-close" style="margin-right: 15px;" @click="backToList" />
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div align="center" style="margin-top: 10px;">
              <el-pagination
                :page-size="meta.per_page"
                :pager-count="5"
                :total="meta.total"
                :current-page.sync="meta.page"
                layout="total, prev, pager, next"
                @current-change="getAll()"
              />
            </div>
          </el-row>
        </el-card>
      </el-col>
      <el-col v-if="fil_selected" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card shadow="never">
          <el-row>
            <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22">
              <h3 style="margin-top: 5px;">{{ dialog_title }} <a type="text" style="font-size: 14px; color: green;" @click="attachmentUpload()"> New <i class="fas fa-plus-square"/></a></h3>
            </el-col>
            <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" align="right">
              <!--
              <el-button type="danger" size="mini" icon="el-icon-close" circle style="position: absolute; top: -13px; right: -13px;" @click="backToList" />
              -->
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <h3 style="color: #28a73a; margin-top: 5px; word-break: break-word;">
                {{ selected.fullname }}
              </h3>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form :inline="true" label-position="right">
                <el-form-item>
                  <el-date-picker v-model="yearSearch" type="year" size="medium" placeholder="Pick a year" style="width:150px; float: right" clearable />
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="naf">No Attached File</el-checkbox>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row>
            <el-table v-loading="loading1" ref="ipcrTable" :data="ipcrFile" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
              <el-table-column type="expand" label="..." width="48">
                <template slot-scope="props">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                      <p class="para"><strong>Year: </strong>{{ props.row.year }}</p>
                      <p class="para"><strong>Rating period: </strong>{{ props.row.rating_period }}</p>
                      <p class="para"><strong>Added by: </strong><span :title="getFormattedTimeStampDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.add_by_personnel.name }}</span></p>
                      <p v-if="props.row.updated_by_personnel_id" class="para"><strong>Updated by: </strong><span :title="getFormattedTimeStampDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')" class="para">{{ props.row.update_by_personnel.name }}</span></p>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="year" label="Year" width="100" />
              <el-table-column label="Rating period" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.rating_period === 'January to June'" :key="'ifsnipcr6' + scope.row.id"><el-tag size="mini" type="primary" effect="dark" style="height: 10px;" /> {{ scope.row.rating_period }}</span>
                  <span v-else :key="'elsesnipcr7' + scope.row.id"><el-tag size="mini" type="warning" effect="dark" style="height: 10px;" /> {{ scope.row.rating_period }}</span>
                </template>
              </el-table-column>
              <el-table-column width="" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.path" style="word-break: break-word"><el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">File Uploaded</el-tag></span>
                  <span v-else style="word-break: break-word"><el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag></span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Operation (s)" width="130" align="right">
                <template slot-scope="scope">
                  <el-button-group>
                    <el-tooltip v-if="scope.row.path" :key="'iftpipcr8' + scope.row.id" content="View Attached File" placement="top">
                      <el-button :key="'ifbnipcr9' + scope.row.id" type="primary" icon="el-icon-document" size="mini" style="margin-right: 10px;" @click="viewAttachmentFile(scope.row.path)" />
                    </el-tooltip>
                    <el-tooltip :key="'elsetpipcr10' + scope.row.id" content="Edit" placement="top">
                      <el-button :key="'elsebnipcr11' + scope.row.id" type="primary" icon="el-icon-edit" size="mini" style="margin-right: 10px;" @click="update(scope.row)" />
                    </el-tooltip>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
            <div align="center" style="margin-top: 10px;">
              <el-pagination
                :page-size="ipcrmeta.ipcr_page"
                :pager-count="5"
                :total="ipcrmeta.total"
                :current-page.sync="ipcrmeta.page"
                layout="total, prev, pager, next"
                @current-change="getIPCRFile()"
              />
            </div>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      v-el-drag-dialog
      v-if="upDown"
      :visible.sync="upDown"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="25%"
      top="5vh"
      append-to-body
      @close="handleClose()">
      <el-row style="margin: -50px 0;">
        <div style="font-size: 18px; margin-bottom: 10px; margin-top: 10px; font-weight: bold">
          {{ selected.fullname }}
        </div>
        <div style="font-size: 18px">{{ modalTitle }}</div>
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form :model="con_selected" :rules="rules">
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="Year" prop="year">
                <el-date-picker v-model="con_selected.year" type="year" style="width:100%" size="medium" placeholder="Please Select Year" clearable />
              </el-form-item>
              <el-form-item label="Rating period" prop="rating_period">
                <el-select v-model="con_selected.rating_period" name="rating_period" size="medium" placeholder="Select rating period" style="width: 100%" clearable>
                  <el-option value="January to June" label="January to June">January to June</el-option>
                  <el-option value="July to December" label="July to December">July to December</el-option>
                </el-select>
              </el-form-item>
              <el-form-item ref="path" label="Attached File">
                <i-p-c-r-o-p-c-r-uploader-component v-if="upDown" :path.sync="con_selected.path" class="my-upload-job" @setAttachment="con_selected.path = $event" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="saveButton.saving" type="primary" size="medium" @click.once="save">{{ saveButton.text }}</el-button>
        <el-button type="danger" size="medium" @click="upDown = false">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-el-drag-dialog
      v-if="upFile"
      :visible.sync="upFile"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="25%"
      top="5vh"
      append-to-body
      @close="handleClose()">
      <el-row style="margin: -50px 0;">
        <div style="font-size: 18px; margin-bottom: 10px; margin-top: 10px; font-weight: bold">
          {{ selected.fullname }}
        </div>
        <div style="font-size: 18px">{{ upTitle }}</div>
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form :model="up_selected">
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="Year" >
                <el-date-picker v-model="up_selected.year" type="year" style="width:100%" size="medium" placeholder="Please Select Year" clearable />
              </el-form-item>
              <el-form-item label="Rating period">
                <el-select v-model="up_selected.rating_period" size="medium" placeholder="Select rating period" style="width: 100%" clearable >
                  <el-option value="January to June" label="January to June">January to June</el-option>
                  <el-option value="July to December" label="July to December">July to December</el-option>
                </el-select>
              </el-form-item>
              <el-form-item ref="path" label="Attached File">
                <i-p-c-r-o-p-c-r-uploader-component v-if="upFile" :path.sync="up_selected.path" class="my-upload-job" @setAttachment="up_selected.path = $event" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <!--        <el-button :loading="saveButton.saving" type="primary" size="medium" @click="saveUP()">{{ saveButton.text }}</el-button>-->
        <el-button :loading="saveButton.saving" type="primary" size="medium" @click.once="saveUP">{{ saveButton.text }}</el-button>
        <el-button type="danger" size="medium" @click="upFile = false">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeAttachmentFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { IPCR } from '@/api/records/ipcr'
import { IpcrPersonnel } from '@/api/records/ipcr/ipcr_personnel'
import { Personnel } from '@/api/personnel'
import { Notification } from 'element-ui'
import IPCROPCRUploaderComponent from '../../layout/components/Records/IPCROPCRUploaderComponent'
import elDragDialog from '@/directive/el-drag-dialog' // base on element-ui

export default {
  name: 'IPCR',
  components: { IPCROPCRUploaderComponent },
  directives: { elDragDialog },
  data() {
    return {
      pager_count: 3,
      meta: {
        page: 1
      },
      // per_meta: {
      //   page: 1
      // },
      ipcrmeta: {
        ipcr_page: 1
      },
      naf: false,
      path_s: null,
      title: null,
      ipcrFile: [],
      ipcr_personnel: [],
      departments: [],
      personnel: null,
      unit_id: null,
      years: null,
      per_name: null,
      depart: [],
      name_personnel: null,
      rates: null,
      modalTitle: '',
      upTitle: '',
      refyears: '',
      dialog_title: null,
      loading: false,
      loading1: false,
      selected: {},
      editable: false,
      editable_monitoring: false,
      fil_selected: null,
      up_selected: null,
      upDown: false,
      upFile: false,
      searchterm: null,
      deptype: null,
      yearSearch: null,
      disable: true,
      input_year: false,
      input_rating: false,
      SelectPersonnelDia: 'Please input personnel name',
      saveButton: {
        saving: false,
        text: 'Save'
      },
      con_selected: {
        rating_period: null,
        year: null
      },
      rules: {
        rating_period: [
          { required: true, message: 'Rating period is required', trigger: 'blur' }
        ],
        year: [
          { required: true, message: 'Must set year', trigger: 'blur' }
        ]
      },
      base_url: this.$store.getters.base_url,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      view_file: null,
      view_attach_form: false,
      ipcr_id: null,
      personnel_id: null
    }
  },
  watch: {
    // searchterm: function() {
    //   if (this.searchterm.length >= 3 || this.searchterm.length === 0) {
    //     this.getAll()
    //   }
    // },
    // deptype: function() {
    //   this.getAll()
    // },
    yearSearch: function() {
      if (this.yearSearch !== null) {
        this.yearSearch = this.getFormattedDate(this.yearSearch)
      }
      this.getIPCRFile()
    },
    naf: function() {
      this.ipcrmeta.page = 1
      if (this.naf === true) {
        this.path_s = 1
      } else {
        this.path_s = null
      }
      this.getIPCRFile()
    },
    'con_selected.rating_period': function(value) {
      this.input_rating = !(value === null || value === '')
      this.checkInputs()
    },
    'con_selected.year': function(value) {
      this.input_year = !(value === null || value === '')
      this.checkInputs()
    }
  },
  created: function() {
    this.getAll()
    this.getCreateItems()
  },
  methods: {
    checkInputs() {
      this.disable = !(this.input_rating)
    },
    getAll() {
      // this.fil_selected = null
      this.loading = true
      IpcrPersonnel({
        page: this.meta.page,
        per_name: this.searchterm,
        unit_id: this.deptype
      }).then(res => {
        this.ipcr_personnel = res.data.ipcr_personnel
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    getCreateItems() {
      Personnel(null, 'list').then(res => {
        this.departments = res.data.departments
      }).catch(error => {
        console.log(error)
      })
    },
    getIPCRFile() {
      this.loading1 = true
      IPCR({
        personnel_id: this.personnel_id,
        yearSearch: this.yearSearch,
        page: this.ipcrmeta.page,
        path: this.path_s
      }).then(res => {
        this.ipcrFile = res.data.ipcr
        this.ipcrmeta = res.data.meta
        this.loading1 = false
      }).catch(error => {
        console.log(error)
        this.loading1 = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      this.fil_selected = null
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.fil_selected = null
      this.searchterm = null
      this.deptype = null
      this.ipcr_id = null
      this.meta.page = 1
      this.getAll()
    },
    getName(obj, fieldName) {
      if (obj) {
        return obj[fieldName]
      }
      return 'N/A'
    },
    attachmentUpload() {
      this.upDown = true
      this.con_selected = Object.assign({})
      this.modalTitle = 'New IPCR'
      this.con_selected.personnel_id = this.selected.id
      this.con_selected.orig_path = null
      this.loading = false
    },
    getFormattedDate(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    getFormattedTimeStampDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    add() {
      this.selected = {}
      this.title = 'New IPCR'
      this.editable = true
    },
    update(index) {
      this.upFile = true
      this.up_selected = Object.assign({}, index)
      this.up_selected.year = "'" + this.up_selected.year + "'"
      this.upTitle = 'Edit IPCR'
      this.up_selected.personnel_id = this.selected.id
      this.up_selected.orig_path = this.up_selected.path
      // console.log('this.up_selected:', this.up_selected)
    },
    view(obj) {
      this.selected = Object.assign({}, obj)
      this.ipcr_id = this.selected.id
      this.edit(obj)
      // console.log('obj:', obj)
    },
    backToList() {
      this.fil_selected = null
      this.selected = {}
      this.ipcr_id = null
      this.personnel_id = null
      // this.loading = true
      // this.getAll()
    },
    edit(index) {
      this.fil_selected = Object.assign({}, index)
      // this.editable = true
      this.dialog_title = 'IPCR files'
      this.personnel_id = this.fil_selected.id
      this.ipcrmeta.page = 1
      this.getIPCRFile()
    },
    saveUP: function() {
      if (this.up_selected.rating_period === undefined) {
        Notification.error({
          title: 'IPCR error',
          message: 'IPCR rating period is required!',
          duration: 4 * 1000
        })
      } else if (this.up_selected.year === undefined) {
        Notification.error({
          title: 'IPCR error',
          message: 'IPCR Year is required!',
          duration: 4 * 1000
        })
      } else {
        this.up_selected.year = this.getFormattedDate(this.up_selected.year)
        this.up_selected.type = 'IPCR'
        var action = this.up_selected.id ? 'update' : 'create'
        IPCR(this.up_selected, action).then(res => {
          if (res.data.success === false) {
            Notification.error({
              title: 'Error!',
              message: 'Already exists',
              duration: 5 * 1000
            })
          } else {
            Notification.success({
              title: 'Success',
              message: 'IPCR has been saved successfully',
              duration: 5 * 1000
            })
            this.saveButton.text = 'Save'
            this.saveButton.saving = false
            this.getIPCRFile()
            this.handleCloseUp()
          }
        }).catch(error => {
          console.log(error)
          this.saveButton.text = 'Save'
          this.saveButton.saving = false
        })
      }
    },
    save: function() {
      if (this.con_selected.rating_period === undefined) {
        Notification.error({
          title: 'IPCR error',
          message: 'IPCR must set a rating period!',
          duration: 4 * 1000
        })
      } else if (this.con_selected.year === undefined) {
        Notification.error({
          title: 'IPCR error',
          message: 'IPCR must set a year!',
          duration: 4 * 1000
        })
      } else {
        this.con_selected.id = null
        this.con_selected.type = 'IPCR'
        this.con_selected.year = this.getFormattedDate(this.con_selected.year)
        var action = this.con_selected.id ? 'update' : 'create'
        IPCR(this.con_selected, action).then(res => {
          if (res.data.success === false) {
            Notification.error({
              title: 'Error!',
              message: 'Already exists',
              duration: 5 * 1000
            })
          } else {
            Notification.success({
              title: 'Success',
              message: 'IPCR has been saved successfully',
              duration: 5 * 1000
            })
            this.saveButton.text = 'Save'
            this.saveButton.saving = false
            this.getIPCRFile()
            this.handleClose()
          }
        }).catch(error => {
          console.log(error)
          this.saveButton.text = 'Save'
          this.saveButton.saving = false
        })
      }
    },
    handleClose() {
      this.getAll()
      this.upDown = false
    },
    handleCloseUp() {
      this.upFile = false
    },
    getFormattedTimeStamps() {
      return this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    handleCurrentChange(val) {
      this.currentRow = val
    },
    viewAttachmentFile(path) {
      // window.open(this.base_url + '/records/ipcr/download/' + file, '_blank')
      this.view_file = this.base_url + '/records/ipcr/download/' + path
      this.view_attach_form = true
    },
    closeAttachmentFile() {
      this.view_file = null
      this.view_attach_form = false
    }
  }
}

</script>

